import styled from "styled-components";

export const XButton = styled.button`
  width: 96px;
  height: 45px;
  border-radius: 9px;
  background: linear-gradient(180deg, #FFEB79 0%, #FFD244 100%);
  font-weight: bold;
  color: #A46007;
  font-size: 14px;
  transition: opacity .2s linear;
  &[disabled] {
    background: #C6CDCC;
    color: #FFF;
    cursor: not-allowed;
    pointer-events: none;
    &:hover {
      opacity: 1;
    }
  }
  &:hover {
    opacity: .8;
  }
`
export const BuyButton = styled.button`
  width: 300px;
  height: 50px;
  background: #00F4E4;
  border-radius: 7px;
  color: #022981;
  font-weight: bold;
  transition: opacity .2s linear;
  &[disabled] {
    background: #C6CDCC;
    color: #FFF;
    cursor: not-allowed;
    pointer-events: none;
    &:hover {
      opacity: 1;
    }
  }
  &:hover {
    opacity: .8;
  }
`

export const ReceiveButton = styled.button`
  width: 110px;
  height: 32px;
  background: #6090F8;
  border-radius: 16px;
  font-size: 14px;
  line-height: 16px;
  color: #FFFFFF;
  transition: opacity .2s linear;
  &[disabled] {
    background: #C6C6C6;
    color: #FFF;
    cursor: not-allowed;
    pointer-events: none;
    &:hover {
      opacity: 1;
    }
  }
  &:hover {
    opacity: .8;
  }
`