import { useWeb3React } from '@web3-react/core'
import { isEqualString } from 'utils'

interface Currency {
  name: string
  symbol: string
  decimals: number
}

interface ENVType {
  CHAIN_ID: number
  currency: Currency
  ADDRESS_COCOSVIPBOOK: string
  ADDRESS_COCOSTOKEN: string
  ADDRESS_COCOS_BLINDBOXNFT: string
  ADDRESS_COCOSFANTASYBLINDBOX: string
  ADDRESS_OPENED_NFT: string
  ADDRESS_COCOS_LAUNCHPAD_CONTROLLER: string
  ADDRESS_LAUNCHPAD_POOL: string
  ADDRESS_COCOS_POWER: string
  ADDRESS_COCOSDIVIDENDSTEAM: string
  ADDRESS_COCOS_V1_NFT: string
  ADDRESS_COCOS_V1_NFT_FACTORY: string
  ADDRESS_COCOS_V1_NFT_BOXPROXY: string
  ADDRESS_PLAYERBOOK: string
  API_PREFIX: string
  EXCHANGE_CONTRACT: string
  REGISTRY_CONTRACT: string
  FEE_RECIPIENT_ADDRESS: string
  EXPLORE_URL: string
}

export const isTest = process.env.REACT_APP_PUBLIC_ENV === 'test'

const BSC: ENVType = {
  CHAIN_ID: 56,
  currency: {
    name: 'BNB',
    symbol: 'BNB',
    decimals: 18,
  },
  ADDRESS_COCOS_BLINDBOXNFT: '0x4fF341f19Adc21169459979AC8c89f142eeb3585',
  ADDRESS_COCOSTOKEN: '0xAb301DaE71f5B386C566f484e636Aee60318F12F',
  ADDRESS_COCOSVIPBOOK: '0x6f698A0bf68a5d64f3319Af19Fbfe259d4172f31',
  ADDRESS_COCOSFANTASYBLINDBOX: '0xe542c572e6E3692AFa5089E9435731E9D1a6Bb5a',
  ADDRESS_OPENED_NFT: '0xd7c79abeb8d8b21e7638a8aadfdcc1438d24b483',
  ADDRESS_COCOS_LAUNCHPAD_CONTROLLER: '0xf55d89CC0911C110Bf1eafc618f72Afb072468c4',
  ADDRESS_LAUNCHPAD_POOL: '0xd23D5830449D56F7E6c25DeA15AD1d820369a0aB',
  ADDRESS_COCOS_POWER: '0x867fCF8A1D2c0320C81Ffcd6775eD7a496619600',
  ADDRESS_COCOSDIVIDENDSTEAM: '0x957f22A61E587Fdf1EA9094A0E19a9ABa66F0eBd',
  ADDRESS_COCOS_V1_NFT: '0xEEdb8A865e2908dAA203A4b119B52805564E947A',
  ADDRESS_COCOS_V1_NFT_FACTORY: '0xf5a33E4079c025f88387240fc4de3757659c06a7',
  ADDRESS_COCOS_V1_NFT_BOXPROXY: '0xf08a7274fD5FF3154Ffd2325cE5af976C30C366B',
  ADDRESS_PLAYERBOOK: '0x63062B5f2CC762632684734574BAff42d3F756CA',
  API_PREFIX: 'https://api.cocosbcx.io/v1',
  EXCHANGE_CONTRACT: '0x339185f035051fF3e6270ab572C61bC6418e1381',
  REGISTRY_CONTRACT: '0x15adAFCdebB30c46f34fF2c98979582f24e30594',
  FEE_RECIPIENT_ADDRESS: '0x2024298572c4Dd595bb42371c2419390AA57baa9',
  EXPLORE_URL: 'https://bscscan.com',
}

const KOVAN: ENVType = {
  CHAIN_ID: 42,
  currency: {
    name: 'BNB',
    symbol: 'BNB',
    decimals: 18,
  },
  ADDRESS_COCOSVIPBOOK: '0x16B7d1793E9b62536907357F900Bc55dAc66677A',
  ADDRESS_COCOSTOKEN: '0xD617fBaF0a30d94d030eBac078496E9Db3AC6136',
  ADDRESS_COCOS_BLINDBOXNFT: '0xc9CD945B61d7Ee1d110c0aA50B1fe5D72B54DCd9',
  ADDRESS_COCOSFANTASYBLINDBOX: '0xDF6834c1dF29a14efAc0258B98E128Ed287865F4',
  ADDRESS_OPENED_NFT: '0x05C822321759D68657e90e92AeAffdbb823899A7',
  ADDRESS_COCOS_LAUNCHPAD_CONTROLLER: '0xEc984D75c294215B9131a49611f6410B699747d6',
  ADDRESS_LAUNCHPAD_POOL: '0xEc984D75c294215B9131a49611f6410B699747d6',
  ADDRESS_COCOS_POWER: '0xc5D497E5843fCAFAd604143F498EF5145334969B',
  ADDRESS_COCOSDIVIDENDSTEAM: '',
  ADDRESS_COCOS_V1_NFT: '0x0aa2f7d123e06fdb84daffc7ae594261f49c6c45',
  ADDRESS_COCOS_V1_NFT_FACTORY: '0x4d4B14f45Af4501561C39b6D1D16bE3E1e04F597',
  ADDRESS_COCOS_V1_NFT_BOXPROXY: '',
  ADDRESS_PLAYERBOOK: '',
  API_PREFIX: 'https://api-test.cocosbcx.io/v1', // 测试
  EXCHANGE_CONTRACT: '0x8edf9a8922f80da8d861ca53115ff70ebcd1024c',
  REGISTRY_CONTRACT: '0x6e05E4722fFF952EEdAB74357Fc15fE41F6F36a8',
  FEE_RECIPIENT_ADDRESS: '0xd8Ebb2d2Bb442AE7D31e15f3d722212EF5B2E27A',
  EXPLORE_URL: 'https://kovan.etherscan.io',
}

// polygon测试配置
export const POLYGON_TEST: ENVType = {
  CHAIN_ID: 137,
  currency: {
    name: 'MATIC',
    symbol: 'MATIC',
    decimals: 18,
  },
  ADDRESS_COCOSVIPBOOK: '0x0b88Cb5afEE20a152f52cCa0a60DA6AB8F0aC4f0', // 测试
  ADDRESS_COCOSTOKEN: '0xd1AE92Af50423dB2FDF5bf324604edCB3d54b440', // 测试
  ADDRESS_COCOS_BLINDBOXNFT: '0xD096A729524d729F1B7c4bb78BEfc9CD0b19D029', // 测试
  ADDRESS_COCOSFANTASYBLINDBOX: '',
  ADDRESS_OPENED_NFT: '',
  ADDRESS_COCOS_LAUNCHPAD_CONTROLLER: '',
  ADDRESS_LAUNCHPAD_POOL: '',
  ADDRESS_COCOS_POWER: '',
  ADDRESS_COCOSDIVIDENDSTEAM: '',
  ADDRESS_COCOS_V1_NFT: '',
  ADDRESS_COCOS_V1_NFT_FACTORY: '',
  ADDRESS_COCOS_V1_NFT_BOXPROXY: '',
  ADDRESS_PLAYERBOOK: '',
  API_PREFIX: 'https://api-test.cocosbcx.io/v1', // 测试
  EXCHANGE_CONTRACT: '0xf5a33E4079c025f88387240fc4de3757659c06a7',
  REGISTRY_CONTRACT: '0x98ad9D54bCC358619fb34E3B1D43501C0C158a3c',
  FEE_RECIPIENT_ADDRESS: '0x2024298572c4Dd595bb42371c2419390AA57baa9',
  EXPLORE_URL: 'https://polygonscan.com',
}

// polygon正式配置
const POLYGON: ENVType = {
  CHAIN_ID: 137,
  currency: {
    name: 'MATIC',
    symbol: 'MATIC',
    decimals: 18,
  },
  ADDRESS_COCOSVIPBOOK: '', // 测试
  ADDRESS_COCOSTOKEN: '', // 测试
  ADDRESS_COCOS_BLINDBOXNFT: '', // 测试
  ADDRESS_COCOSFANTASYBLINDBOX: '',
  ADDRESS_OPENED_NFT: '',
  ADDRESS_COCOS_LAUNCHPAD_CONTROLLER: '',
  ADDRESS_LAUNCHPAD_POOL: '',
  ADDRESS_COCOS_POWER: '',
  ADDRESS_COCOSDIVIDENDSTEAM: '',
  ADDRESS_COCOS_V1_NFT: '',
  ADDRESS_COCOS_V1_NFT_FACTORY: '',
  ADDRESS_COCOS_V1_NFT_BOXPROXY: '',
  ADDRESS_PLAYERBOOK: '',
  API_PREFIX: 'https://api.cocosbcx.io/v1',
  EXCHANGE_CONTRACT: '0xf5a33E4079c025f88387240fc4de3757659c06a7',
  REGISTRY_CONTRACT: '0x98ad9D54bCC358619fb34E3B1D43501C0C158a3c',
  FEE_RECIPIENT_ADDRESS: '0x2024298572c4Dd595bb42371c2419390AA57baa9',
  EXPLORE_URL: 'https://polygonscan.com',
}

export default function useEnvConfig() {
  const { chainId } = useWeb3React()
  switch (chainId) {
    case 42:
      return KOVAN
    case 56:
      return BSC
    case 137:
      return isTest ? POLYGON_TEST : POLYGON
    default:
      return BSC
  }
}

export function useIsPolygon() {
  const { chainId } = useWeb3React()
  if (!chainId) return false
  return isEqualString(`${chainId}`, `${POLYGON.CHAIN_ID}`)
}
