import { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { createPortal } from 'react-dom'

const modalEl = document.getElementById('modal')
export default function DialogBox(props: { show: boolean; children: any }) {
  const el = useRef<HTMLElement | null>(null)
  const ref = useRef(null)
  if (!el.current) {
    el.current = document.createElement('div')
    modalEl && modalEl.appendChild(el.current)
  }
  useEffect(() => {
    if (props.show) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'inherit'
    }
  }, [props.show])
  return createPortal(props.show ? <DialogBoxWrapper ref={ref}>{props.children}</DialogBoxWrapper> : null, el.current)
}

const DialogBoxWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  z-index: 999;
  cursor: pointer;
  /* padding-top: 150px; */
`
