import { createContext, useCallback, useContext, useState } from 'react'
import DialogBox from './component'
import styled from 'styled-components'
import { STATICS } from '../../static'

export enum DialogType {
  NONE,
  Waiting,
  Success,
  Error,
}

type ElementType = string | JSX.Element | JSX.Element[]

interface OpenOption {
  title?: string
  desc?: ElementType
  content?: ElementType
  close?: boolean
  icon?: string
}

export const DialogContext = createContext<{
  open: (type: DialogType, option?: OpenOption) => void
  close: () => void
}>({
  open: () => {},
  close: () => {},
})

function getDefaultTitle(type: DialogType) {
  return type === DialogType.Waiting
    ? 'Waiting'
    : type === DialogType.Success
    ? 'Success！'
    : type === DialogType.Error
    ? 'Failed!'
    : ''
}

function getDefaultIcon(type: DialogType) {
  return type === DialogType.Waiting
    ? STATICS.DialogLoadingIcon
    : type === DialogType.Success
    ? STATICS.DialogSuccessIcon
    : STATICS.DialogErrorIcon
}

export default function DialogProvider(props: { children?: ElementType }) {
  const [type, setType] = useState<DialogType>(DialogType.NONE)
  const [title, setTitle] = useState('')
  const [desc, setDesc] = useState<ElementType>('')

  const open = useCallback((type: DialogType, option?: OpenOption) => {
    setTitle(option?.title || '')
    setDesc(option?.desc || '')
    setType(type)
  }, [])

  const close = useCallback(() => {
    open(DialogType.NONE)
  }, [open])
  return (
    <DialogContext.Provider value={{ open, close }}>
      {props.children}
      <DialogBox show={type !== DialogType.NONE}>
        <DialogContent>
          <Title>{title || getDefaultTitle(type)}</Title>
          <Icon className={type === DialogType.Waiting ? 'waiting' : ''} src={getDefaultIcon(type)} />
          {desc && <Desc>{desc}</Desc>}
          <CloseIcon src={STATICS.CloseIcon} onClick={close} />
        </DialogContent>
      </DialogBox>
    </DialogContext.Provider>
  )
}

export function useDialog() {
  return useContext(DialogContext)
}

const DialogContent = styled.div`
  width: 424px;
  height: 329px;
  border-radius: 30px;
  background: #fff;
  padding-top: 71px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
`
const Icon = styled.img`
  width: 64px;
  height: 64px;
  margin-top: 36px;
`
const Title = styled.h3`
  line-height: 28px;
  color: #000;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
`

const Desc = styled.p`
  font-size: 16px;
  color: #666;
  line-height: 18px;
  margin-top: 39px;
  text-align: center;
  padding: 0 20px;
`

const CloseIcon = styled.img`
  width: 18px;
  height: 18px;
  position: absolute;
  top: 22px;
  right: 22px;
`
