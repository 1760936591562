import React, {createContext, useContext, useState} from "react";
import RegisterModalContent from "./register-modal-content";
import Modal from "../../components/Modal";

interface RegisterModalContentParams {
    open: () => void
    close: () => void
}

const RegisterModalContext = createContext<RegisterModalContentParams>({
    open: () => {},
    close: () => {}
})

export function RegisterModalProvider(props: {
    children?: any
}) {
    const [show, setShow] = useState(false)
    return <RegisterModalContext.Provider value={{
        open: () => setShow(true),
        close: () => setShow(false)
    }}>
        {props.children}
        <Modal open={show}>
            <RegisterModalContent onClose={() => setShow(false)}/>
        </Modal>
    </RegisterModalContext.Provider>
}

export function useRegisterModal() {
    return useContext(RegisterModalContext)
}