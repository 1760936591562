import { useContext, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { WalletModalContext } from '../../context/WalletModalContext'
import TabMenu, { MenuItemType } from '../TabMenu'
import Logo from '../common/Logo'
import Chain from './chain'
import { useWeb3React } from '@web3-react/core'
import { useContract } from '../../hooks/useContract'
import { shortenAddress } from '../../utils'
import { STATICS, Polygon_STATICS } from '../../static'
import { Link } from 'react-router-dom'
import useEnvConfig from 'hooks/useEnvConfig'
import { ABI_PLAYBOOK } from '../../utils/abis'
import { useIsPolygon } from 'hooks/useEnvConfig'
import Web3 from 'web3'

const bscMenus: MenuItemType[] = [
  {
    title: 'LaunchPad',
    url: '/launchpad',
  },
  {
    title: 'Mystery Box',
    url: '/mysterybox',
  },
  {
    title: 'Staking',
    routers: [
      {
        title: 'Dividend',
        url: 'https://bsc.cocosbcx.io/dividends',
      },
      {
        title: 'NFTCast',
        url: '/casting',
      },
    ],
  },
  {
    title: 'Marketplace',
    url: '/marketplace',
  },
  {
    title: 'Governance',
    url: 'https://snapshot.org/#/cocoslabs.eth',
  },
  {
    title: 'Docs',
    url: 'https://docs.cocosbcx.io/',
  },
]

export default function Header() {
  const { ADDRESS_PLAYERBOOK } = useEnvConfig()
  const { account, active, library, chainId } = useWeb3React()
  const { toggle } = useContext(WalletModalContext)
  const [menuslist, setmenusList] = useState(bscMenus)
  const cocosContract = useContract(ADDRESS_PLAYERBOOK, ABI_PLAYBOOK)
  const isPolygon = useIsPolygon()

  useEffect(() => {
    if (account && cocosContract) {
      cocosContract.getPlayerName(account).then((res) => {
        let web3 = new Web3(library.provider)
        let name = web3.utils.toAscii(res)
        let lastRefname = ''
        let myRefName = name
        let emptyAscii = '0x0000000000000000000000000000000000000000000000000000000000000000'
        if (web3.utils.fromAscii(myRefName) !== emptyAscii && myRefName !== '') {
          let myRefNameAscii = web3.utils.fromAscii(myRefName)
          let myReAsciiStopNo = 0
          for (let i = 0; i < 33; i++) {
            let test = String(myRefNameAscii).slice(i, i + 2)
            if (test === '00') {
              myReAsciiStopNo = i
              break
            }
          }

          let myRefNameAsciiLast = ''
          if (myRefNameAscii.length === 66) {
            myRefNameAsciiLast = String(myRefNameAscii).slice(0, myReAsciiStopNo)
          } else {
            myRefNameAsciiLast = myRefNameAscii
          }

          lastRefname = web3.utils.toAscii(myRefNameAsciiLast)
        }
        if (lastRefname) {
          let menuslists: any = bscMenus
          for (let i = 0; i < menuslists.length; i++) {
            if (menuslists[i].title === 'Staking') {
              menuslists[i].routers.push({
                title: 'Referral',
                url: 'https://bsc.cocosbcx.io/referral',
              })
            }
          }
          setmenusList(menuslists)
        }
      })
    }
  }, [account, cocosContract, library, chainId])

  const menusDisplay = useMemo(() => {
    if (chainId === 137) {
      const copyObj: MenuItemType[] = JSON.parse(JSON.stringify(menuslist))
      return copyObj
        .filter((menu) => menu.title !== 'Staking')
        .map((menu) => {
          if (['LaunchPad'].includes(menu.title)) {
            // 'Marketplace'
            menu.disabled = true
          }
          return menu
        })
    }
    return menuslist
  }, [chainId, menuslist])

  return (
    <HeaderContent>
      <Logo />
      <TabMenuContainer>
        <TabMenu menus={menusDisplay} />
        {active && (
          <StyledLink to={'/account'}>
            <Avatar src={isPolygon ? Polygon_STATICS.Polygon_avatar : STATICS.Avatar} />
          </StyledLink>
        )}
        <Chain />
        {active ? (
          <WalletButton onClick={toggle} isPolygon={isPolygon}>
            {account && shortenAddress(account)}
          </WalletButton>
        ) : (
          <WalletButton onClick={toggle} isPolygon={isPolygon}>
            Connect Wallet
          </WalletButton>
        )}
      </TabMenuContainer>
    </HeaderContent>
  )
}

const HeaderContent = styled.div`
  position: absolute;
  top: 0;
  z-index: 1;
  width: 1400px;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const TabMenuContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

const WalletButton = styled.button<{ isPolygon?: boolean }>`
  background: rgba(255, 255, 255, 0.1);
  border-color: ${({ isPolygon }) => (isPolygon ? '#F42BFF' : '#00F4E4')};
  color: #fff;
  display: inline-block;
  width: 137px;
  height: 40px;
  border-radius: 20px;
  border-style: solid;
  border-width: 2px;
  text-align: center;
  font-size: 16px;
  transition: opacity 0.2s linear;
  &:hover {
    opacity: 0.8;
  }
`

const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  right: 140px;
  cursor: pointer;
  transition: opacity 0.2s linear;
  &:hover {
    opacity: 0.7;
  }
`

const StyledLink = styled(Link)`
  margin-left: 35px;
`
