import React from "react";
import styled from "styled-components";
import ModalContent from "../../components/Modal/modal-content";
import {XButton} from "../../components/Button";
import {Link} from "react-router-dom";
import {STATICS} from "../../static";

export default function RegisterModalContent(props: {
    onClose: () => void
    text?: string
}) {
    return <RegisterModalContentWrapper onClose={props.onClose}>
        <Icon src={STATICS.BlindVip}/>
        <Tips>{props.text || 'Only when you become a member of COCOS can you make a purchase'}</Tips>
        <Link to={"/account"} target={'_blank'}>
            <RegisterButton >Register member</RegisterButton>
        </Link>
    </RegisterModalContentWrapper>
}

const RegisterModalContentWrapper = styled(ModalContent)`
  width: 390px;
  height: 340px;
  padding: 48px 38px 0 38px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`

const Icon = styled.img`
  height: 70px;
`
const Tips = styled.p`
  margin-top: 22px;
  font-size: 14px;
  color: #6090F8;
  line-height: 20px;
  text-align: center;
`


const RegisterButton  =styled(XButton)`
  width: 266px;
  height: 45px;
  border-radius: 23px;
  margin-top: 47px;
`