import { Route, Routes, useLocation } from 'react-router-dom'
import {Suspense, useEffect} from 'react'
import Header from './components/layout/header'
import UserAgreementModal from './components/UserAgreementModal'
import { DialogType, useDialog } from './context/DialogContext'
import { routes } from './routes'
import styled, {createGlobalStyle} from 'styled-components'
// import PreLoad from "./components/common/PreLoad";

export default function App() {
  const location = useLocation()
  const { open } = useDialog()

  useEffect(() => {
    if (location.pathname === '/') {
      window.document.body.classList.remove('gradient')
    } else {
      window.document.body.classList.add('gradient')
    }
  }, [location])

  useEffect(() => {
    open(DialogType.NONE)
  }, [location.pathname, open])
    // const [entered, setEntered] = useState(window.location.pathname !== '/' || Date.now() < 1677623400000 + 60 * 60 * 1000)

  return (
    // entered ? 
    <Container>
        <GlobalStyle/>
      <Header />
      <UserAgreementModal />
      <Routes>
        {routes.map((route) => (
          <Route
            key={route.name}
            path={route.path}
            element={<Suspense fallback={route.fallback}>{route.route}</Suspense>}
          />
        ))}
      </Routes>
    </Container> 
    // : <PreLoad onEnter={() => setEntered(true)}/>
  )
}

const Container = styled.div`
  position: relative;
  width: 1400px;
  margin: 0 auto;
  padding-top: 80px;
`
const GlobalStyle = createGlobalStyle`
  body {
    background: #00246D;
  }
`