import { useRef } from 'react'
import styled from 'styled-components'
import { createPortal } from 'react-dom'

const modalEl = document.getElementById('modal')
export default function Modal(props: { open: boolean; onClose?: () => void; children?: any }) {
  const el = useRef<HTMLElement | null>(null)
  const ref = useRef(null)
  if (!el.current) {
    el.current = document.createElement('div')
    modalEl && modalEl.appendChild(el.current)
  }

  return createPortal(
    props.open ? (
      <ModalWrapper
        ref={ref}
        onClick={(e) => {
          if (e.target === ref.current) {
            props.onClose && props.onClose()
          }
        }}
      >
        {props.children}
      </ModalWrapper>
    ) : null,
    el.current,
  )
}

const ModalWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
`
