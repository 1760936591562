import { InjectedConnector } from '@web3-react/injected-connector'
import { AbstractConnector } from '@web3-react/abstract-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { WalletLinkConnector } from '@web3-react/walletlink-connector'
import { isTest } from 'hooks/useEnvConfig'

import Onto from '../static/image/wallets/onto.svg'
import Tokenpocket from '../static/image/wallets/tokenpocket.png'
import Safepel from '../static/image/wallets/safepel.png'
import Mathwallet from '../static/image/wallets/mathwallet.png'
import Trustwallet from '../static/image/wallets/trustwallet.png'
import WalletConnectIcon from '../static/image/wallets/walletConnectIcon.svg'
import Metamask from '../static/image/wallets/metamask.png'
import Injected from '../static/image/wallets/injected.svg'
import CoinBase from '../static/image/wallets/coinbase.png'

export enum ChainId {
  Ethereum = 1,
  Ropsten = 3,
  Rinkeby = 4,
  Goerli = 5,
  Kovan = 42,
  BSC = 56,
  BSCTest = 97,
  Polygon = 137,
}

export const supportedChainIds = isTest ? [42, 56, 137] : [56, 137]

export const injected = new InjectedConnector({
  supportedChainIds,
})

export const BSCNetWork = {
  chainId: '0x38',
  chainName: 'Binance Smart Chain Mainnet',
  nativeCurrency: {
    name: 'BNB',
    symbol: 'BNB',
    decimals: 18,
  },
  rpcUrls: ['https://bsc-dataseed1.ninicoin.io'],
  blockExplorerUrls: ['https://bscscan.com'],
}

export const KovanNetwork = {
  chainId: '0x2a',
  chainName: 'Kovan 测试网络',
  nativeCurrency: {
    name: 'ETH',
    symbol: 'ETH',
    decimals: 18,
  },
  rpcUrls: ['https://kovan.infura.io/v3/undefined'],
  blockExplorerUrls: ['https://kovan.etherscan.io'],
}

export const PolygonNetwork = {
  chainId: '0x89',
  chainName: 'Polygon',
  nativeCurrency: {
    name: 'MATIC',
    symbol: 'MATIC',
    decimals: 18,
  },
  rpcUrls: ['https://polygon-mainnet.infura.io/v3/295cce92179b4be498665b1b16dfee34'],
  blockExplorerUrls: ['https://polygonscan.com'],
}

export const trust_injected = new InjectedConnector({
  supportedChainIds,
})


export const coin_base_injected = new WalletLinkConnector({
  url: 'https://bsc-dataseed1.defibit.io',
  appName: 'cocosbcx',
  supportedChainIds,
})


export const math_injected = new InjectedConnector({
  supportedChainIds,
})

export const safepal_injected = new InjectedConnector({
  supportedChainIds,
})

export const tokenpocket_injected = new InjectedConnector({
  supportedChainIds,
})

export const onto_injected = new InjectedConnector({
  supportedChainIds,
})

export const walletconnect = new WalletConnectConnector({
  rpc: {
    56: 'https://bsc-dataseed1.defibit.io',
  },
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
  // @ts-ignore
  pollingInterval: 12000,
})

export const walletConnectNoModal = new WalletConnectConnector({
  rpc: {
    56: 'https://bsc-dataseed1.defibit.io',
  },
  bridge: 'https://bridge.walletconnect.org',
  qrcode: false,
  // @ts-ignore
  pollingInterval: 12000,
})

interface WalletInfo {
  connector?: AbstractConnector
  name: string
  iconName: any
  description: string
  href: string | null
  color: string
  primary?: true
  mobile?: true
  mobileOnly?: true
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  INJECTED: {
    connector: injected,
    name: 'Injected',
    iconName: Injected,
    description: 'Injected web3 provider.',
    href: null,
    color: '#010101',
    primary: true,
  },
  METAMASK: {
    connector: injected,
    name: 'MetaMask',
    iconName: Metamask,
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D',
  },
  COINBASE: {
    connector: coin_base_injected,
    name: 'Coinbase Wallet',
    iconName: CoinBase,
    description: '',
    href: null,
    color: '#E8831D',
  },
  WALLET_CONNECT: {
    connector: walletconnect,
    name: 'WalletConnect',
    iconName: WalletConnectIcon,
    description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
    href: null,
    color: '#4196FC',
    mobile: true,
  },
  TRUSTWALLET: {
    connector: trust_injected,
    name: 'Trust Wallet',
    iconName: Trustwallet,
    description: '',
    href: null,
    color: '#E8831D',
  },
  MATHWALLET: {
    connector: math_injected,
    name: 'Math Wallet',
    iconName: Mathwallet,
    description: '',
    href: null,
    color: '#E8831D',
  },
  SAFEPAL: {
    connector: safepal_injected,
    name: 'Safepal',
    iconName: Safepel,
    description: '',
    href: null,
    color: '#E8831D',
  },
  TOKENPOCKET: {
    connector: tokenpocket_injected,
    name: 'Token Pocket',
    iconName: Tokenpocket,
    description: '',
    href: null,
    color: '#E8831D',
  },
  ONTO: {
    connector: onto_injected,
    name: 'ONTO Wallet',
    iconName: Onto,
    description: '',
    href: null,
    color: '#E8831D',
  },
}
