import { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { BigNumber, constants } from 'ethers'
import { useContracts, useProvider } from '../../hooks/useContract'
import useEnvConfig, { useIsPolygon } from 'hooks/useEnvConfig'
import { ABI_COCOS_VIP, COCOS_MAPPING_VIP } from '../../utils/abis'
import { useWeb3React } from '@web3-react/core'
import { parseBytes32String } from 'ethers/lib/utils'
import { isEqualString } from '../../utils'
import POLYGON_WHITE_LIST from 'utils/abis/whitelist.json'

interface VipInfo {
  isWhiteList: boolean
  isRegistered: null | boolean
  registerFee: BigNumber
  maxRegisterCount: number
  soldRegisterCount: number
  lv1Count: number
  lv2Count: number
  referReward: BigNumber
  name: string
}

const VipInfoContext = createContext<VipInfo>({
  isWhiteList: false,
  isRegistered: null,
  registerFee: constants.Zero,
  maxRegisterCount: 0,
  soldRegisterCount: 0,
  lv1Count: 0,
  lv2Count: 1,
  referReward: constants.Zero,
  name: '',
})

export function useVipInfo() {
  return useContext(VipInfoContext)
}

const defaultInfo = {
  isWhiteList: false,
  isRegistered: null,
  registerFee: constants.Zero,
  maxRegisterCount: 0,
  soldRegisterCount: 0,
  lv1Count: 0,
  lv2Count: 1,
  referReward: constants.Zero,
  name: '',
}

// export function useWhiteList() {
//   const { account } = useWeb3React()
//   return useMemo(() => {
//     if (!account) return null
//     // @ts-ignore
//     const white_list = Object.entries(POLYGON_WHITE_LIST.claims)
//     const info = white_list.find((item) => isEqualString(item[0], account))
//     return info
//   }, [account])
// }

export function VipInfoProvider(props: { children?: any }) {
  const { ADDRESS_COCOSVIPBOOK } = useEnvConfig()
  const isPolygon = useIsPolygon()
  const [info, setInfo] = useState<VipInfo>(defaultInfo)
  const { contract, multiCallContract } = useContracts(
    ADDRESS_COCOSVIPBOOK,
    isPolygon ? COCOS_MAPPING_VIP : ABI_COCOS_VIP,
  )
  const provider = useProvider()
  const { account, active } = useWeb3React()
  const refresh = useCallback(() => {
    if (isPolygon) {
      // @ts-ignore
      const white_list = Object.entries(POLYGON_WHITE_LIST.claims)
      const info = white_list.find((item) => isEqualString(item[0], account))
      if (info && provider && account && active && multiCallContract) {
        provider
          // @ts-ignore
          .all([multiCallContract.hasRegistered(info[0], info[1].index, info[1].proof)])
          .then((data) => {
            setInfo((prev) => {
              return {
                ...prev,
                isRegistered: data[0],
                isWhiteList: data[0],
              }
            })
          })
      } else {
        setInfo(defaultInfo)
      }
    } else {
      if (multiCallContract && provider && account && active) {
        provider
          .all([
            multiCallContract.hasRegistered(account),
            multiCallContract._whitelists(account),
            multiCallContract.getRegistrationFee(),
            multiCallContract._maxRegisterCount(),
            multiCallContract._soldRegisterCount(),
            multiCallContract.getVIPInfoxAddress(account),
            multiCallContract._registrationStep(),
            multiCallContract._stepFee(),
            multiCallContract._registrationBaseFee(),
          ])
          .then((data) => {
            setInfo({
              isRegistered: data[0],
              isWhiteList: data[1],
              registerFee: data[4].div(data[6]).mul(data[7]).add(data[8]),
              maxRegisterCount: +data[3],
              soldRegisterCount: +data[4],
              lv1Count: +data[5].lv1Count,
              lv2Count: +data[5].lv2Count,
              referReward: data[5].referReward,
              name: parseBytes32String(data[5].name),
            })
          })
      } else {
        setInfo(defaultInfo)
      }
    }
  }, [provider, multiCallContract, account, active, isPolygon])

  useEffect(() => {
    if (contract?.provider && !isPolygon) {
      const listener = () => {
        refresh()
      }
      const claimListener = (vipId: BigNumber, address: string, amount: BigNumber) => {
        if (isEqualString(address, account)) {
          setInfo((prevState) => Object.assign({}, prevState, { referReward: constants.Zero }))
        }
      }
      contract?.on('eveNewRegister', listener)
      contract?.on('eveClaim', claimListener)
      return () => {
        contract?.removeListener('eveNewRegister', listener)
        contract?.removeListener('eveClaim', claimListener)
      }
    }
  }, [contract, account, refresh, isPolygon])

  useEffect(() => {
    refresh()
  }, [account, contract, refresh])

  return <VipInfoContext.Provider value={info}>{props.children}</VipInfoContext.Provider>
}
