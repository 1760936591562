import {createContext, useCallback, useState} from "react";
import WalletModal from "../../components/WalletModal";

export const WalletModalContext = createContext<{ open: boolean; toggle: () => void }>({
    open: false,
    toggle: () => { },
})

export default function WalletModalProvider(props: {
    children?: any
}) {
    const [open, setOpen] = useState(false)
    const toggle = useCallback(() => {
        setOpen(prevState => !prevState)
    }, [])
    return <WalletModalContext.Provider value={{open, toggle}}>
        {props.children}
        <WalletModal/>
    </WalletModalContext.Provider>
}