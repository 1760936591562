import GitHubIcon from './image/icons/github.png'
import DiscordIcon from './image/icons/discord.png'
import MediumIcon from './image/icons/medium.png'
import TelegramIcon from './image/icons/telegram.png'
import TwitterIcon from './image/icons/twitter.png'
import CocosLogo from './image/logo.png'
import HomeBg from './image/home/bg.png'

import DialogErrorIcon from './image/dialog/error.png'
import DialogSuccessIcon from './image/dialog/success.svg'
import DialogLoadingIcon from './image/dialog/loading.svg'

import CloseIcon from './image/icons/close.png'
import VipIcon from './image/icons/vip.svg'
import VipCard from './image/member/vipcard.svg'
import VipReact from './image/member/react.svg'
import VipBannerBg from './image/member/vipbg.png'
import VipCardBg from './image/member/v-bg.png'
import Avatar from './image/icons/avatar.png'

import ComingBg from './image/coming.png'
import ComingTips from './image/tips.svg'
import MarketPlace from './image/market-place.png'
import Launch from './image/launch.png'
import BlindBox from './image/blind-box.png'

import XIcon from './image/icons/x.svg'
import BSCIcon from './image/chain/bsc.png'
import CopyIcon from './image/icons/copy.png'

import DialogClose from './image/icons/dialog-close.png'
import CircleClose from './image/icons/close-circle.svg'
import BlindVip from './image/icons/blind-vip.svg'
import BlindBoxBox from './image/blindbox/box.png'
import BlindBoxLight from './image/blindbox/light.png'
import MoreIcon from './image/icons/more.png'
import MoreHoverIcon from './image/icons/more-hover.png'
import BoardIcon from './image/board.svg'

import ArrowLeftIcon from './image/arrow/left.svg'
import ArrowLeftSelectedIcon from './image/arrow/left-selected.svg'
import ArrowLeftDisabledIcon from './image/arrow/left-disabled.svg'

import XGithub from './image/xicon/x-github.svg'
import XNetwork from './image/xicon/x-network.svg'
import XTelegram from './image/xicon/x-telegram.svg'
import XTwitter from './image/xicon/x-twitter.svg'
import XDiscord from './image/xicon/xdiscord.png'
import IGOContent from './image/content/igo.svg'

import SuccessIcon from './image/icons/success.svg'
import FailIcon from './image/icons/fail.svg'

import RuleBtn from './image/common/rule-btn.png'
import DividendsBanner from './image/dividend/banner.png'
import DividendsLine from './image/dividend/line.png'
import DividendsIcon1 from './image/dividend/icon11.svg'
import DividendsIcon2 from './image/dividend/icon22.svg'
import DividendsIcon3 from './image/dividend/icon33.svg'
import DividendsCreateIcon from './image/common/create.png'
import DividendsLine1 from './image/dividend/line1.svg'
import DividendsDefaultIcon from './image/dividend/default_icon.png'
import DividendsSelectIcon from './image/dividend/select-icon.png'

import CommonCloseBtn from './image/common/close.svg'

import CastKeyBlade from './image/casting/KeyBlade.png'
import CastKeyBladeSelect from './image/casting/KeyBlade_select.png'
import CastquestionMark from './image/casting/questionMark.png'
import CastquestionMarkSelect from './image/casting/questionMark_select.png'
import Castsword from './image/casting/sword.png'
import CastswordSelect from './image/casting/sword_select.png'
import Caststaff from './image/casting/staff.png'
import CaststaffSelect from './image/casting/staff_select.png'
import Castsickle from './image/casting/sickle.png'
import CastsickleSelect from './image/casting/sickle_select.png'
import Casttrident from './image/casting/trident.png'
import CasttridentSelect from './image/casting/trident_select.png'
import CastDecomposeBtn from './image/casting/decompose-btn.svg'
import CaststarSelect from './image/casting/star-select.png'
import Caststar from './image/casting/star.png'

import DropIcon from './image/common/drop-icon.svg'
import EmptyList from './image/common/emptyList.png'

import Disassemble from './image/nft/disassemble.png'

import LevelIcon from './image/common/level.svg'
import IncreaseIcon from './image/common/increase.svg'
import PowerIcon from './image/common/power.svg'

import CocosPowerLogo from './image/logos/cocos.png'
import NFTPowerLogo from './image/logos/hero.png'

import RecordIcon from './image/icons/record.svg'
import FantasyBox from './image/blindbox/fantasybox.png'

import backBtn from './image/common/back.svg'
import EmptyIcon from './image/empty.svg'

import Polygon_bg from './image/polygon/bg.png'
import Polygon_discord from './image/polygon/discord.svg'
import Polygon_medium from './image/polygon/medium.svg'
import Polygon_github from './image/polygon/github.svg'
import Polygon_tele from './image/polygon/tele.svg'
import Polygon_twitter from './image/polygon/twitter.svg'
import Polygon_avatar from './image/polygon/avatar.svg'

import PreLoadTextIcon from './image/preload/text.png'
import PreloadLineIcon from './image/preload/line.png'
import PreloadLogo from './image/preload/logo.png'
import PreloadMask from './image/preload/mask.png'

import Change from './image/combo/change.png'
// import CocosLogo from './image/combo/cocos_logo.png'
import ComboLogo from './image/combo/combo_logo.png'

export const Polygon_STATICS = {
  Polygon_bg,
  Polygon_discord,
  Polygon_medium,
  Polygon_github,
  Polygon_tele,
  Polygon_twitter,
  Polygon_avatar,
}

export const STATICS = {
  GitHubIcon,
  DiscordIcon,
  MediumIcon,
  TelegramIcon,

  TwitterIcon,
  CocosLogo,
  HomeBg,
  DialogErrorIcon,
  DialogSuccessIcon,
  CloseIcon,
  DialogLoadingIcon,

  VipIcon,
  VipReact,
  VipBannerBg,
  VipCard,
  VipCardBg,

  Avatar,
  ComingBg,
  ComingTips,

  MarketPlace,
  Launch,
  BlindBox,

  XIcon,
  BSCIcon,
  CopyIcon,
  DialogClose,

  CircleClose,
  BlindVip,
  BlindBoxBox,
  BlindBoxLight,

  MoreHoverIcon,
  MoreIcon,
  ArrowLeftIcon,
  ArrowLeftDisabledIcon,
  ArrowLeftSelectedIcon,

  XGithub,
  XTelegram,
  XTwitter,
  XNetwork,
  XDiscord,
  IGOContent,

  FailIcon,
  SuccessIcon,

  RuleBtn,
  DividendsBanner,
  DividendsLine,
  DividendsIcon1,
  DividendsIcon2,
  DividendsIcon3,
  DividendsCreateIcon,

  DividendsLine1,
  DividendsDefaultIcon,
  DividendsSelectIcon,

  CommonCloseBtn,

  CastKeyBlade,
  CastKeyBladeSelect,
  CastquestionMark,
  CastquestionMarkSelect,
  Castsword,
  CastswordSelect,
  Caststaff,
  CaststaffSelect,
  Castsickle,
  CastsickleSelect,
  Casttrident,
  CasttridentSelect,
  CastDecomposeBtn,
  CaststarSelect,
  Caststar,

  DropIcon,
  EmptyList,
  Disassemble,

  LevelIcon,
  PowerIcon,
  IncreaseIcon,

  CocosPowerLogo,
  NFTPowerLogo,

  RecordIcon,
  FantasyBox,

  backBtn,
  BoardIcon,
  EmptyIcon,

  PreloadLineIcon,
  PreLoadTextIcon,
  PreloadLogo,
  PreloadMask,

  Change,
  // CocosLogo,
  ComboLogo,
}
