import { Web3ReactProvider } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'

import ReactDOM from 'react-dom'
import './style/index.css'
import './style/quill.css'
import App from './App'
import WalletModalProvider from './context/WalletModalContext'
import DialogProvider from './context/DialogContext'
import { VipInfoProvider } from './context/VipInfoContext'
import { BrowserRouter } from 'react-router-dom'
import { RegisterModalProvider } from './context/RegisterModalContext'

function getLibrary(provider: any): Web3Provider {
  const library = new Web3Provider(provider, 'any')
  library.pollingInterval = 15000
  return library
}

ReactDOM.render(

  <BrowserRouter>
    <Web3ReactProvider getLibrary={getLibrary}>
      <WalletModalProvider>
        <DialogProvider>
          <VipInfoProvider>
            <RegisterModalProvider>
              <App />
            </RegisterModalProvider>
          </VipInfoProvider>
        </DialogProvider>
      </WalletModalProvider>
    </Web3ReactProvider>
  </BrowserRouter>,
  document.getElementById('root'),
)
