import { CSSProperties } from 'react'
import styled from 'styled-components'
import { STATICS } from '../../static'
import { Link } from 'react-router-dom'

export default function Logo(props: { style?: CSSProperties }) {
  return (
    <Link to={'/'}>
      <LogoImage style={props.style} src={STATICS.CocosLogo} />
    </Link>
  )
}
const LogoImage = styled.img`
  width: 140px;
  height: 62px;
`
