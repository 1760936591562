import { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import storage from '../../utils/storage'
import { content } from './agreement'
import Modal from '../Modal'
import { ReceiveButton as Button } from '../Button'

const AGREEMENT_KEY = 'AGREE_AGREEMENT'

export default function UserAgreementModal() {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    const agreed = storage.get(AGREEMENT_KEY)
    const timer = setTimeout(() => {
      setOpen(!agreed)
    }, 500)
    return () => {
      clearTimeout(timer)
    }
  }, [])

  const onOk = useCallback(() => {
    storage.set(AGREEMENT_KEY, true)
    setOpen(false)
  }, [])

  return (
    <Modal open={open}>
      <ModalWrapper>
        <ModalTitle>Agreement Statement</ModalTitle>
        <ModalBody dangerouslySetInnerHTML={{ __html: content }}></ModalBody>
        <Footer>
          <Button style={{ color: '#6090F8', background: '#fff' }} onClick={() => setOpen(false)}>
            Disagree
          </Button>
          <Button style={{ marginLeft: 20 }} onClick={onOk}>
            Agree
          </Button>
        </Footer>
      </ModalWrapper>
    </Modal>
  )
}

const ModalWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 664px;
  border-radius: 30px;
  background: #ffffff;
  overflow: hidden;
`

const ModalTitle = styled.div`
  height: 60px;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: PingFangSC-Medium, PingFang SC;
  font-size: 20px;
  font-weight: 500;
  background: #f8f8f8;
  border-bottom: none;
`

const ModalBody = styled.div`
  padding: 0;
  margin: 30px 26px 21px 38px;
  height: 360px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background: #eee;
  }
  ::-webkit-scrollbar-track {
    background: #fff;
  }
`

const Footer = styled.div`
  height: 86px;
  background: #f8f8f8;
  display: flex;
  align-items: center;
  justify-content: center;
`
