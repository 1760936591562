import { CSSProperties } from 'react'
import styled from 'styled-components'
import { STATICS } from '../../static'

export default function ModalContent(props: {
  onClose?: () => void
  children?: any
  className?: string
  style?: CSSProperties
}) {
  return (
    <ContentWrapper className={props.className} style={props.style}>
      {props.onClose && <CloseIcon src={STATICS.DialogClose} onClick={props.onClose} />}
      {props.children}
    </ContentWrapper>
  )
}

const ContentWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 390px;
  height: 320px;
  background: linear-gradient(159deg, #11388c 0%, #01246e 100%);
  border-radius: 20px;
  /* margin: 150px auto 0 auto; */
`
const CloseIcon = styled.img`
  width: 18px;
  height: 18px;
  position: absolute;
  top: 26px;
  right: 26px;
  cursor: pointer;
`
