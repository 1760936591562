import { BigNumber, Contract, providers } from 'ethers'
import { isAddress } from 'ethers/lib/utils'
import { Contract as MulContract } from 'ethers-multicall'
import moment, { Moment } from 'moment'

export function getMulContract(address: string, ABI: any): MulContract | null {
  try {
    return new MulContract(address, ABI)
  } catch (e) {
    console.log('e', e)
    return null
  }
}

export function getContract(address: string, ABI: any, signer?: providers.JsonRpcSigner | null): Contract | null {
  try {
    return new Contract(address, ABI, signer || undefined)
  } catch (e) {
    console.error('getContract', e)
    return null
  }
}

export function parseAmounts(balance: number | string, decimal: number = 18) {
  return decimal < 6
    ? BigNumber.from(+balance * 1000000)
        .div(BigNumber.from(10).pow(6 - decimal))
        .toString()
    : BigNumber.from(+balance * 1000000)
        .mul(BigNumber.from(10).pow(decimal - 6))
        .toString()
}

export function shortenAddress(address: string, chars = 4): string {
  const parsed = isAddress(address)
  if (!parsed) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }
  return `${address.substring(0, chars + 2)}...${address.substring(42 - chars)}`
}

export function getReferCode(): string {
  return localStorage.referCode || ''
}

export function setReferCode(code: string) {
  localStorage.referCode = code
}

export const dateCommonFormat = function (date: any, fmt: string) {
  let ret
  const opt = {
    'Y+': date.getFullYear().toString(),
    'm+': (date.getMonth() + 1).toString(),
    'd+': date.getDate().toString(),
    'H+': date.getHours().toString(),
    'M+': date.getMinutes().toString(),
    'S+': date.getSeconds().toString(),
  }
  for (let k in opt) {
    ret = new RegExp('(' + k + ')').exec(fmt)
    if (ret) {
      fmt = fmt.replace(ret[1], ret[1].length === 1 ? opt[k] : opt[k].padStart(ret[1].length, '0'))
    }
  }
  return fmt
}

export function isEqualString(str1: string | undefined | null, str2: string | undefined | null): boolean {
  if (!str1 || !str2) return false
  return str1.toLowerCase() === str2.toLowerCase()
}

export function formatDecimals(value: number | string, decimals: number = 2) {
  return +(+value).toFixed(decimals)
}

export function calculateGasMargin(value: BigNumber): BigNumber {
  return value.mul(BigNumber.from(10000).add(BigNumber.from(1000))).div(BigNumber.from(10000))
}

export function setCookie(name: string, value: string) {
  // if (!isBrowser) return
  var Days = 30
  var exp: any = new Date()
  exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000)
  document.cookie = name + '=' + escape(value) + ';expires=' + exp.toGMTString()
}

export function formatNumber(num: string | number) {
  const numStr = num + ''
  const [integer, float] = numStr.split('.')
  return integer.replace(/(\d)(?=(\d{3})+$)/g, '$1,') + (float ? '.' + float : '')
}

export function duration2Time(duration: number) {
  if (duration < 0) {
    return '00:00:00'
  } else {
    const hour = Math.floor(duration / 3600)
      .toString()
      .padStart(2, '0')
    const min = Math.floor((duration % 3600) / 60)
      .toString()
      .padStart(2, '0')
    const sec = (duration % 60).toString().padStart(2, '0')
    return `${hour}:${min}:${sec}`
  }
}

export function formatCountDown(count: number) {
  if (count > 0) {
    const day = Math.floor(count / 86400)
      .toString()
      .padStart(2, '0')
    const hour = Math.floor((count % 86400) / 3600)
      .toString()
      .padStart(2, '0')
    const min = Math.floor((count % 3600) / 60)
      .toString()
      .padStart(2, '0')
    const sec = Math.floor(count % 60)
      .toString()
      .padStart(2, '0')
    return `${day} D : ${hour} H : ${min} M : ${sec} S`
  }
  return `00 D : 00 H : 00 M : 00 S`
}

export const KeepSecimals = function (num, digit) {
  let balance = num
  let indexPoint = String(balance).indexOf('.')
  if (indexPoint > 0) {
    if (balance.length > indexPoint + digit) {
      balance = balance.substr(0, indexPoint + digit)
    }
  }
  return balance
}

export const balanceFormat = function (balance, digitNum) {
  balance = String(balance)
  let digit: number = Number(String(digitNum)) || 18
  if (String(balance).length <= digit) {
    let supplyZeroNum = digit - String(balance).length
    let decimalPoint = '0.'
    for (let i = 0; i < supplyZeroNum; i++) {
      decimalPoint += '0'
    }
    balance = decimalPoint + balance
  } else {
    let after = balance.slice(String(balance).length - digit, String(balance).length)
    let before = balance.slice(0, String(balance).length - digit)
    balance = before + '.' + after
  }
  return balance
}

export function formatBalance(balance: any, decimals: number = 18) {
  const num = BigNumber.from(typeof balance === 'number' ? balance + '' : balance)
  return decimals >= 4
    ? num.div(BigNumber.from(10).pow(decimals - 4)).toNumber() / 10000
    : num.mul(BigNumber.from(10).pow(4 - decimals)).toNumber() / 10000
}

export const hideStr = function (str, startBit, endBit) {
  let startStr = str.slice(0, Number(startBit))
  let strLength = str.length
  let endStr = str.slice(strLength - Number(endBit), strLength)
  return startStr + '***' + endStr
}

export const timeFormat = (lefttime) => {
  let d: number | string = parseInt(String(lefttime / (24 * 60 * 60)), 0)
  let h: number | string = parseInt(String((lefttime / (60 * 60)) % 24), 0)
  let m: number | string = parseInt(String((lefttime / 60) % 60), 0)
  let s: number | string = parseInt(String(lefttime % 60), 0)
  d = addZero(d)
  h = addZero(h)
  m = addZero(m)
  s = addZero(s)
  return {
    d,
    h,
    m,
    s,
  }
}

export const addZero = function (i: number | string) {
  return i < 10 ? '0' + i : i + ''
}

export const toPercent = function (point) {
  // var str = Number(point * 100).toFixed(2);
  let str: string = KeepSecimals(String(point * 100), 3)
  str += '%'
  return str
}

export const balanceFormatAdd = function (balance, digitNum) {
  let result = ''
  balance = String(balance)
  let digit: number | string = String(digitNum) || 18
  let pointIndex = String(balance).indexOf('.')
  if (pointIndex > 0) {
    let first = ''
    let balanceArr = balance.split('.')
    if (balanceArr[0] === '0') {
      first = ''
    } else {
      first = balanceArr[0]
    }
    let surplusLength = Number(digit) - balanceArr[1].length
    result = first + balanceArr[1]
    for (let i = 0; i < surplusLength; i++) {
      result += '0'
    }
    // result = Number(result)
    result = String(result)
  } else {
    for (let i = 0; i < digit; i++) {
      balance += '0'
    }
    result = balance
  }
  return result
}

export const getArrEqual = function (arr1, arr2) {
  let newArr: any = []
  for (let i = 0; i < arr2.length; i++) {
    for (let j = 0; j < arr1.length; j++) {
      if (arr1[j] === arr2[i]) {
        newArr.push(arr1[j])
      }
    }
  }
  return newArr
}

export default function fixedFloat(num: number, decimals: number = 4) {
  return +num.toFixed(decimals)
}

export function getUTCTimestamp(momentObj: Moment) {
  return +momentObj.utc('+0' as any).format('X')
}

export function transformToUTC(timestamp: string | number) {
  return moment.unix(+timestamp).utc()
}

export function flattenParams(object: { [key: string]: string[] }) {
  let str = ''
  for (let key in object) {
    let arr = object[key]
    str = arr.reduce((s, t) => `${s}${key}=${t}&`, str)
  }
  return str
}

export function transformErcType(type: string | undefined) {
  if (!type) return '--'
  if (type === 'erc721') return 'ERC-721'
  else if (type === 'erc1155') return 'ERC-1155'
  return '--'
}
