import { lazy } from 'react'
import Loading from '../components/common/Loading'

const pageLoading = <Loading height={100} />

const Home = lazy(() => import('../page/home'))
const Account = lazy(() => import('../page/account'))
const Vip = lazy(() => import('../page/vip'))
const MarketPlace = lazy(() => import('./../page/marketplace'))
const NftDetail = lazy(() => import('../page/nftdetail'))
const BlindBoxList = lazy(() => import('../page/blindbox'))
const BlindBoxDetail = lazy(() => import('../page/blindbox/detail'))
const Detail = lazy(() => import('../page/detail'))
const MoreOpened = lazy(() => import('../page/viewmore/opened'))
const MoreUnOpened = lazy(() => import('../page/viewmore/blindbox'))
const Launchpad = lazy(() => import('./../page/igolist'))
const IgoDetail = lazy(() => import('./../page/igodetail'))
const MyIGODetail = lazy(() => import('../page/myigodetail'))
const Casting = lazy(() => import('../page/casting'))
const MyNft = lazy(() => import('../page/mynft'))

export const routes = [
  { path: '/', name: 'home', fallback: pageLoading, route: <Home /> },
  { path: '/account', name: 'account', fallback: pageLoading, route: <Account /> },
  { path: '/vip', name: 'vip', fallback: null, route: <Vip /> },
  { path: '/launchpad', name: 'launchpad', fallback: null, route: <Launchpad /> },
  { path: '/igo-detail', name: 'igoDetail', fallback: null, route: <IgoDetail /> },
  { path: '/blindbox-list', name: 'blindboxList', fallback: null, route: <BlindBoxList /> },
  { path: '/blindbox-detail', name: 'blindboxDetail', fallback: null, route: <BlindBoxDetail /> },
  { path: '/marketplace', name: 'marketplace', fallback: null, route: <MarketPlace /> },
  { path: '/nftdetail', name: 'nftDetail', fallback: null, route: <NftDetail /> },
  { path: '/blindbox-info', name: 'blindboxInfo', fallback: null, route: <Detail /> },
  { path: '/viewmore/unopened', name: 'unopened', fallback: null, route: <MoreUnOpened /> },
  { path: '/viewmore/opened', name: 'opened', fallback: null, route: <MoreOpened /> },
  { path: '/casting', name: 'casting', fallback: null, route: <Casting /> },
  { path: '/mynft', name: 'myNft', fallback: null, route: <MyNft /> },
  { path: '/myigo-detail', name: 'myIgoDetail', fallback: null, route: <MyIGODetail /> },
  { path: '/mysterybox', name: 'mysterybox', fallback: null, route: <BlindBoxList /> },
  { path: '/mysterybox-info', name: 'mysteryboxInfo', fallback: null, route: <Detail /> },
]
