const abi = [
	{
		"constant": true,
		"inputs": [],
		"name": "_governance",
		"outputs": [
			{
				"name": "",
				"type": "address"
			}
		],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
	},
	{
		"constant": true,
		"inputs": [],
		"name": "_maxTLevel",
		"outputs": [
			{
				"name": "",
				"type": "uint256"
			}
		],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
	},
	{
		"constant": true,
		"inputs": [],
		"name": "_airdropToken",
		"outputs": [
			{
				"name": "",
				"type": "address"
			}
		],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
	},
	{
		"constant": true,
		"inputs": [
			{
				"name": "user",
				"type": "address"
			},
			{
				"name": "ruleId",
				"type": "uint256"
			}
		],
		"name": "generate",
		"outputs": [
			{
				"components": [
					{
						"name": "id",
						"type": "uint256"
					},
					{
						"name": "grade",
						"type": "uint256"
					},
					{
						"name": "quality",
						"type": "uint256"
					},
					{
						"name": "amount",
						"type": "uint256"
					},
					{
						"name": "resBaseId",
						"type": "uint256"
					},
					{
						"name": "tLevel",
						"type": "uint256"
					},
					{
						"name": "ruleId",
						"type": "uint256"
					},
					{
						"name": "nftType",
						"type": "uint256"
					},
					{
						"name": "author",
						"type": "address"
					},
					{
						"name": "erc20",
						"type": "address"
					},
					{
						"name": "createdTime",
						"type": "uint256"
					},
					{
						"name": "blockNum",
						"type": "uint256"
					}
				],
				"name": "gego",
				"type": "tuple"
			}
		],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
	},
	{
		"constant": false,
		"inputs": [
			{
				"name": "val",
				"type": "uint256"
			}
		],
		"name": "setQualityBase",
		"outputs": [],
		"payable": false,
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"constant": true,
		"inputs": [
			{
				"name": "",
				"type": "uint256"
			}
		],
		"name": "_ruleSwitch",
		"outputs": [
			{
				"name": "",
				"type": "bool"
			}
		],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
	},
	{
		"constant": false,
		"inputs": [
			{
				"name": "factory",
				"type": "address"
			}
		],
		"name": "setFactory",
		"outputs": [],
		"payable": false,
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"constant": false,
		"inputs": [
			{
				"name": "airdropToken",
				"type": "address"
			}
		],
		"name": "setAirdropContract",
		"outputs": [],
		"payable": false,
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"constant": false,
		"inputs": [
			{
				"name": "val",
				"type": "uint256"
			}
		],
		"name": "setMaxGrade",
		"outputs": [],
		"payable": false,
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"constant": false,
		"inputs": [
			{
				"name": "val",
				"type": "uint256"
			}
		],
		"name": "setMaxTLevel",
		"outputs": [],
		"payable": false,
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"constant": false,
		"inputs": [
			{
				"name": "ruleId",
				"type": "uint256"
			},
			{
				"name": "minMintAmount",
				"type": "uint256"
			},
			{
				"name": "maxMintAmount",
				"type": "uint256"
			},
			{
				"name": "costErc20Amount",
				"type": "uint256"
			},
			{
				"name": "mintErc20",
				"type": "address"
			},
			{
				"name": "costErc20",
				"type": "address"
			},
			{
				"name": "minBurnTime",
				"type": "uint256"
			},
			{
				"name": "canMintMaxGrade",
				"type": "bool"
			},
			{
				"name": "canMintMaxTLevel",
				"type": "bool"
			}
		],
		"name": "setRuleData",
		"outputs": [],
		"payable": false,
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"constant": true,
		"inputs": [],
		"name": "_airdopMintAmount",
		"outputs": [
			{
				"name": "",
				"type": "uint256"
			}
		],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
	},
	{
		"constant": true,
		"inputs": [],
		"name": "_costErc20Pool",
		"outputs": [
			{
				"name": "",
				"type": "address"
			}
		],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
	},
	{
		"constant": false,
		"inputs": [
			{
				"components": [
					{
						"name": "user",
						"type": "address"
					},
					{
						"name": "amount",
						"type": "uint256"
					},
					{
						"name": "ruleId",
						"type": "uint256"
					}
				],
				"name": "params",
				"type": "tuple"
			},
			{
				"components": [
					{
						"name": "costErc721Id1",
						"type": "uint256"
					},
					{
						"name": "costErc721Id2",
						"type": "uint256"
					},
					{
						"name": "costErc721Id3",
						"type": "uint256"
					},
					{
						"name": "costErc721Origin",
						"type": "address"
					}
				],
				"name": "costSet1",
				"type": "tuple"
			},
			{
				"components": [
					{
						"name": "costErc721Id1",
						"type": "uint256"
					},
					{
						"name": "costErc721Id2",
						"type": "uint256"
					},
					{
						"name": "costErc721Id3",
						"type": "uint256"
					},
					{
						"name": "costErc721Origin",
						"type": "address"
					}
				],
				"name": "costSet2",
				"type": "tuple"
			}
		],
		"name": "cost",
		"outputs": [
			{
				"name": "mintAmount",
				"type": "uint256"
			},
			{
				"name": "mintErc20",
				"type": "address"
			}
		],
		"payable": false,
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"constant": true,
		"inputs": [],
		"name": "_canAirdrop",
		"outputs": [
			{
				"name": "",
				"type": "bool"
			}
		],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
	},
	{
		"constant": false,
		"inputs": [
			{
				"name": "val",
				"type": "uint256"
			}
		],
		"name": "setMaxGradeLong",
		"outputs": [],
		"payable": false,
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"constant": false,
		"inputs": [
			{
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "setAirdropAmount",
		"outputs": [],
		"payable": false,
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"constant": false,
		"inputs": [
			{
				"name": "b",
				"type": "bool"
			}
		],
		"name": "enableAirdrop",
		"outputs": [],
		"payable": false,
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"constant": false,
		"inputs": [
			{
				"name": "governance",
				"type": "address"
			}
		],
		"name": "setGovernance",
		"outputs": [],
		"payable": false,
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"constant": false,
		"inputs": [
			{
				"name": "ruleId",
				"type": "uint256"
			},
			{
				"name": "enable",
				"type": "bool"
			}
		],
		"name": "enableRule",
		"outputs": [],
		"payable": false,
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"constant": true,
		"inputs": [],
		"name": "_factory",
		"outputs": [
			{
				"name": "",
				"type": "address"
			}
		],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
	},
	{
		"constant": true,
		"inputs": [
			{
				"name": "",
				"type": "uint256"
			}
		],
		"name": "_ruleData",
		"outputs": [
			{
				"name": "minMintAmount",
				"type": "uint256"
			},
			{
				"name": "maxMintAmount",
				"type": "uint256"
			},
			{
				"name": "costErc20Amount",
				"type": "uint256"
			},
			{
				"name": "mintErc20",
				"type": "address"
			},
			{
				"name": "costErc20",
				"type": "address"
			},
			{
				"name": "minBurnTime",
				"type": "uint256"
			},
			{
				"name": "canMintMaxGrade",
				"type": "bool"
			},
			{
				"name": "canMintMaxTLevel",
				"type": "bool"
			}
		],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
	},
	{
		"constant": false,
		"inputs": [
			{
				"name": "owner",
				"type": "address"
			},
			{
				"components": [
					{
						"name": "id",
						"type": "uint256"
					},
					{
						"name": "grade",
						"type": "uint256"
					},
					{
						"name": "quality",
						"type": "uint256"
					},
					{
						"name": "amount",
						"type": "uint256"
					},
					{
						"name": "resBaseId",
						"type": "uint256"
					},
					{
						"name": "tLevel",
						"type": "uint256"
					},
					{
						"name": "ruleId",
						"type": "uint256"
					},
					{
						"name": "nftType",
						"type": "uint256"
					},
					{
						"name": "author",
						"type": "address"
					},
					{
						"name": "erc20",
						"type": "address"
					},
					{
						"name": "createdTime",
						"type": "uint256"
					},
					{
						"name": "blockNum",
						"type": "uint256"
					}
				],
				"name": "gego",
				"type": "tuple"
			}
		],
		"name": "destroy",
		"outputs": [],
		"payable": false,
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"constant": true,
		"inputs": [],
		"name": "_qualityBase",
		"outputs": [
			{
				"name": "",
				"type": "uint256"
			}
		],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
	},
	{
		"constant": true,
		"inputs": [
			{
				"name": "quality",
				"type": "uint256"
			}
		],
		"name": "getGrade",
		"outputs": [
			{
				"name": "",
				"type": "uint256"
			}
		],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
	},
	{
		"constant": true,
		"inputs": [],
		"name": "_maxGradeLong",
		"outputs": [
			{
				"name": "",
				"type": "uint256"
			}
		],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
	},
	{
		"constant": true,
		"inputs": [],
		"name": "_maxGrade",
		"outputs": [
			{
				"name": "",
				"type": "uint256"
			}
		],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"name": "costErc20Pool",
				"type": "address"
			},
			{
				"name": "airdropToken",
				"type": "address"
			}
		],
		"payable": false,
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"name": "ruleId",
				"type": "uint256"
			},
			{
				"indexed": false,
				"name": "minMintAmount",
				"type": "uint256"
			},
			{
				"indexed": false,
				"name": "maxMintAmount",
				"type": "uint256"
			},
			{
				"indexed": false,
				"name": "costErc20Amount",
				"type": "uint256"
			},
			{
				"indexed": false,
				"name": "mintErc20",
				"type": "address"
			},
			{
				"indexed": false,
				"name": "costErc20",
				"type": "address"
			},
			{
				"indexed": false,
				"name": "canMintMaxGrade",
				"type": "bool"
			},
			{
				"indexed": false,
				"name": "canMintMaxTLevel",
				"type": "bool"
			},
			{
				"indexed": false,
				"name": "minBurnTime",
				"type": "uint256"
			}
		],
		"name": "eSetRuleData",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "GovernanceTransferred",
		"type": "event"
	}
]

export default abi