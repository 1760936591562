import { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import usePrevious from '../../hooks/usePrevious'
import Option from './Option'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { AbstractConnector } from '@web3-react/abstract-connector'
import { injected, SUPPORTED_WALLETS, walletConnectNoModal } from '../../utils/connector'
import useSwitchChain from '../../hooks/useSwitchChain'
import { WalletModalContext } from '../../context/WalletModalContext'
import Modal from 'components/Modal'

const CloseIcon = styled.div`
  position: absolute;
  right: 1rem;
  top: 14px;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`

const CloseColor = styled.img`
  path {
    stroke: #c3c5cb;
  }
`

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-flow: column nowrap;
  width: 464px;
  /* height: 578px; */
  background: #ffffff;
  border-radius: 30px;
  overflow: hidden;
  &:focus-within {
    outline: none;
  }
`

const HeaderRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  padding: 1rem 1rem;
  font-weight: 500;
  color: #3e881a;
`

const ContentWrapper = styled.div`
  background-color: #f7f8fa;
  padding: 2rem;
`

const UpperSection = styled.div`
  position: relative;
  background: #fff;

  h5 {
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 400;
  }

  h5:last-child {
    margin-bottom: 0px;
  }

  h4 {
    margin-top: 0;
    font-weight: 500;
  }
`

const OptionGrid = styled.div`
  display: grid;
  grid-gap: 10px;
`

const HoverText = styled.div`
  :hover {
    cursor: pointer;
  }
`

const WALLET_VIEWS = {
  OPTIONS: 'options',
  OPTIONS_SECONDARY: 'options_secondary',
  ACCOUNT: 'account',
  PENDING: 'pending',
}

function onUseWalletConnect() {
  localStorage.walletConnect = 1
}

function onNoUseWalletConnect() {
  localStorage.walletConnect = ''
  localStorage.walletconnect = ''
}

function isLastUseWalletConnect() {
  return localStorage.walletConnect === '1'
}

export default function WalletModal() {
  const switchChain = useSwitchChain()
  const { active, account, connector, activate, error, chainId } = useWeb3React()
  const [walletView, setWalletView] = useState(WALLET_VIEWS.ACCOUNT)

  const { open: walletModalOpen, toggle: toggleWalletModal } = useContext(WalletModalContext)
  const previousAccount = usePrevious(account)
  useEffect(() => {
    if (account && !previousAccount && walletModalOpen) {
      toggleWalletModal()
    }
  }, [account, previousAccount, toggleWalletModal, walletModalOpen])
  useEffect(() => {
    if (walletModalOpen) {
      setWalletView(WALLET_VIEWS.ACCOUNT)
    }
  }, [walletModalOpen])

  const activePrevious = usePrevious(active)
  const connectorPrevious = usePrevious(connector)
  useEffect(() => {
    if (active) {
      if (connector) {
        //@ts-ignore
        if (connector?.walletConnectProvider) {
          onUseWalletConnect()
        } else {
          onNoUseWalletConnect()
        }
      }
    } else {
      if (isLastUseWalletConnect()) {
        activate(walletConnectNoModal)
      } else {
        activate(injected)
      }
    }
  }, [activate, active, connector])
  useEffect(() => {
    if (walletModalOpen && ((active && !activePrevious) || (connector && connector !== connectorPrevious && !error))) {
      setWalletView(WALLET_VIEWS.ACCOUNT)
    }
  }, [setWalletView, active, error, connector, walletModalOpen, activePrevious, connectorPrevious])

  const tryActivation = async (connector: AbstractConnector | undefined) => {
    setWalletView(WALLET_VIEWS.PENDING)

    // if the connector is walletconnect and the user has already tried to connect, manually reset the connector
    if (connector instanceof WalletConnectConnector && connector.walletConnectProvider?.wc?.uri) {
      connector.walletConnectProvider = undefined
    }
    if (connector) {
      const provider = await connector.getProvider()
      if (provider?.isMetaMask && !connector.supportedChainIds?.includes(Number(chainId))) {
        if (connector.supportedChainIds) {
          await switchChain(connector.supportedChainIds[0])
        }
      }
      activate(connector, undefined, true).catch((error) => {
        if (error instanceof UnsupportedChainIdError) {
          activate(connector) // a little janky...can't use setError because the connector isn't set
        }
      })
    }
  }
  // get wallets user can switch too, depending on device/browser
  function getOptions() {
    const win: any = window
    const isMetamask = win.ethereum && win.ethereum.isMetaMask
    return Object.keys(SUPPORTED_WALLETS).map((key) => {
      const option = SUPPORTED_WALLETS[key]

      // overwrite injected when needed
      if (option.connector === injected) {
        // don't show injected if there's no injected provider
        if (!(win.web3 || win.ethereum)) {
          if (option.name === 'MetaMask') {
            return (
              <Option
                id={`connect-${key}`}
                key={key}
                color={'#E8831D'}
                header={'Install Metamask'}
                subheader={null}
                link={'https://metamask.io/'}
                icon={'/image/wallets/metamask.png'}
              />
            )
          } else {
            return null //dont want to return install twice
          }
        }
        // don't return metamask if injected provider isn't metamask
        else if (option.name === 'MetaMask' && !isMetamask) {
          return null
        } else if (option.name === 'Injected' && isMetamask) {
          return null
        }
      }
      return (
        <Option
          id={`connect-${key}`}
          onClick={() => {
            option.connector === connector && active
              ? setWalletView(WALLET_VIEWS.ACCOUNT)
              : !option.href && tryActivation(option.connector)
          }}
          key={key}
          active={
            (option.connector === connector ||
              (option.connector instanceof WalletConnectConnector && connector instanceof WalletConnectConnector)) &&
            active
          }
          color={option.color}
          link={option.href}
          header={option.name}
          subheader={null} //use option.descriptio to bring back multi-line
          icon={option.iconName}
        />
      )
    })
  }

  function getModalContent() {
    return (
      <UpperSection>
        <CloseIcon onClick={toggleWalletModal}>
          <CloseColor />
        </CloseIcon>
        {walletView !== WALLET_VIEWS.ACCOUNT ? (
          <HeaderRow color="blue">
            <HoverText
              onClick={() => {
                setWalletView(WALLET_VIEWS.ACCOUNT)
              }}
            >
              Back
            </HoverText>
          </HeaderRow>
        ) : (
          <HeaderRow>
            <HoverText>Connect to a wallet</HoverText>
          </HeaderRow>
        )}
        <ContentWrapper>
          <OptionGrid>{getOptions()}</OptionGrid>
        </ContentWrapper>
      </UpperSection>
    )
  }

  return (
    // @ts-ignore
    <Modal open={walletModalOpen} onClose={toggleWalletModal}>
      <Wrapper>{getModalContent()}</Wrapper>
    </Modal>
  )
}
