import styled from 'styled-components'
import { useLocation, useNavigate } from 'react-router-dom'
import { useIsPolygon } from 'hooks/useEnvConfig'

export interface MenuItemType {
  title: string
  url?: string
  replace?: boolean
  routers?: any[]
  disabled?: boolean
}

export default function TabMenu(props: { menus: MenuItemType[] }) {
  const navigate = useNavigate()
  const menuNums = props.menus.length
  const location = useLocation()

  return (
    <TabMenuWrapper nums={menuNums}>
      {props.menus.map((menu) => (
        <TabMenuItem
          selected={location.pathname === menu.url}
          key={menu.title}
          disabled={menu.disabled}
          onClick={() => {
            if (menu.url) {
              if (/^https?/.test(menu.url)) {
                if (menu.url.indexOf('bsc.cocosbcx.io') > -1) {
                  window.location.href = menu.url
                } else {
                  window.open(menu.url, menu.replace ? '_self' : '_blank')
                }
              } else {
                navigate(menu.url)
              }
            } else {
              return
            }
          }}
          routers={menu.routers}
          title={menu.title}
        />
      ))}
    </TabMenuWrapper>
  )
}

const TabMenuWrapper = styled.div<{ nums: number }>`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export function TabMenuItem(props: {
  onClick: () => void
  selected: boolean
  title: string
  className?: string
  routers?: any[]
  disabled?: boolean
}) {
  const navigate = useNavigate()
  const location = useLocation()
  const isPolygon = useIsPolygon()
  return (
    <TabMenuItemWrapper
      className={props.className}
      selected={props.selected}
      onClick={() => {
        !props.disabled && props.onClick()
      }}
      isPolygon={isPolygon}
      disabled={props.disabled}
    >
      <span>{props.title}</span>
      {props.routers && (
        <DropListMenuWrapper>
          <DropListMenu className="dropList">
            {props.routers.map((item, key) => (
              <DropLi
                selected={location.pathname === item.url}
                onClick={() => {
                  if (item.url) {
                    if (/^https?/.test(item.url)) {
                      if (item.url.indexOf('bsc.cocosbcx.io') > -1) {
                        window.location.href = item.url
                      } else {
                        window.open(item.url, item.replace ? '_self' : '_blank')
                      }
                    } else {
                      navigate(item.url)
                    }
                  }
                }}
                key={key}
              >
                {item.title}
              </DropLi>
            ))}
          </DropListMenu>
        </DropListMenuWrapper>
      )}
    </TabMenuItemWrapper>
  )
}

const DropListMenuWrapper = styled.div`
  position: absolute;
  top: 60px;
  left: 0px;
`

const DropListMenu = styled.div`
  width: 145px;
  margin-top: 10px;
  background: #1e51be;
  border-radius: 8px;
  padding-left: 20px;
  padding-right: 20px;
`
const DropLi = styled.p<{ selected: boolean }>`
  width: 100%;
  height: 52px;
  line-height: 52px;
  font-size: 16px;
  font-weight: 400;
  color: ${({ selected }) => (selected ? `#FFFFFF` : `#6090F8`)};
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #275fd7;
  &:hover {
    color: #ffffff;
  }
`
const TabMenuItemWrapper = styled.div<{ selected: boolean; isPolygon?: boolean; disabled?: boolean }>`
  padding-right: 25px;
  padding-left: 25px;
  height: 60px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  &::after {
    content: '';
    width: 100%;
    height: 2px;
    background: ${({ isPolygon, selected }) =>
      isPolygon && selected
        ? '#F42BFF'
        : isPolygon && !selected
        ? 'rgba(255,255,255,0.2)'
        : !isPolygon && selected
        ? '#00f4e4'
        : '#135dbd'};
    bottom: 0;
    left: 0;
    position: absolute;
  }
  .dropList {
    display: none;
  }
  &:not([disabled]):hover {
    span {
      color: ${({ isPolygon }) => (isPolygon ? '#F42BFF' : '#00f4e4;')};
    }
    .dropList {
      display: block;
    }
    &::after {
      background: ${({ isPolygon }) => (isPolygon ? '#F42BFF' : '#00f4e4;')};
    }
  }
  span {
    display: inline-block;
    transition: color linear 0.3s;
    color: ${({ isPolygon, selected }) =>
      isPolygon && selected
        ? '#F42BFF'
        : isPolygon && !selected
        ? 'rgba(255,255,255,0.3)'
        : !isPolygon && selected
        ? '#00F4E4'
        : '#3D81D9'};
    line-height: 23px;
  }
`
