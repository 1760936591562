import { useMemo } from 'react'
import styled from 'styled-components'
import { ChevronDown } from 'react-feather'
import { useWeb3React } from '@web3-react/core'
import { supportedChainIds, BSCNetWork, PolygonNetwork, KovanNetwork } from 'utils/connector'
import useSwitchChain from 'hooks/useSwitchChain'
import { useIsPolygon } from 'hooks/useEnvConfig'

const networks = {
  42: {
    ...KovanNetwork,
    name: 'Kovan',
  },
  56: {
    ...BSCNetWork,
    name: 'BSC',
  },
  137: {
    ...PolygonNetwork,
    name: 'Polygon',
  },
}

const networksMap = Object.entries(networks)
const supportedChains = networksMap.filter((network) => supportedChainIds.includes(+network[0]))

export default function Chain() {
  const { chainId } = useWeb3React()
  const switchChain = useSwitchChain()
  const isPolygon = useIsPolygon()

  const curChain = useMemo(() => {
    if (!chainId) return undefined
    return networks[chainId]
  }, [chainId])

  return (
    <ChainMenu>
      {curChain?.name || 'wrong network'}
      <ChevronDown size={20} color="#fff" />
      <ChainDropDownWrapper className="dropdown">
        <ChainDropDown isPolygon={isPolygon}>
          {supportedChains.map((chain, key) => (
            <ChainDropDownItem
              isPolygon={isPolygon}
              key={key}
              active={chainId === +chain[0]}
              onClick={() => switchChain(+chain[1].chainId)}
            >
              {chain[1].name}
            </ChainDropDownItem>
          ))}
        </ChainDropDown>
      </ChainDropDownWrapper>
    </ChainMenu>
  )
}

const ChainMenu = styled.div`
  position: relative;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  border-radius: 20px;
  margin: 0 8px 0 10px;
  font-size: 16px;
  padding: 0 18px;
  font-family: HelveticaNeue;
  cursor: pointer;
  &:hover .dropdown {
    display: block;
  }
`

const ChainDropDownWrapper = styled.div`
  display: none;
  position: absolute;
  top: 41px;
  right: 0;
  background: transparent;
`

const ChainDropDown = styled.div<{ isPolygon?: boolean }>`
  margin-top: 14px;
  width: 140px;
  padding: 0 20px;
  background: ${({ isPolygon }) => (isPolygon ? '#fff' : '#1245b2')};
  border-radius: 8px;
`

const ChainDropDownItem = styled.div<{ active?: boolean; isPolygon?: boolean }>`
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ active, isPolygon }) =>
    isPolygon && active ? '#E153E0' : isPolygon && !active ? '#000' : !isPolygon && active ? '#fff' : '#6090F8'};
  pointer-events: ${({ active }) => (active ? 'none' : 'auto')};
  &:not(:last-child) {
    border-bottom: 1px solid ${({ isPolygon }) => (isPolygon ? '#e6e6e6' : '#275fd7')};
  }
  &:hover {
    color: ${({ isPolygon }) => (isPolygon ? '#E153E0' : '#fff')};
  }
`
