import { CSSProperties } from 'react'
import styled from 'styled-components'
import { STATICS } from '../../static'

export default function Loading(props: { width?: number; height?: number; className?: string; style?: CSSProperties }) {
  return (
    <LoadingWrapper style={props.style} className={props.className} width={props.width} height={props.height}>
      <img src={STATICS.DialogLoadingIcon} alt="" />
    </LoadingWrapper>
  )
}

const LoadingWrapper = styled.div<{ width?: number; height?: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  height: ${({ height }) => (height ? `${height}px` : '100%')};
  background: transparent;
  img {
    height: 100%;
  }
`
